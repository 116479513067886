.App {
  text-align: center;
}

.navbar{
  background-color: white;
  border-bottom: 1px solid #141414;
}


.profileHeader{
  display: flex;
  justify-content: center;
  align-items:center;
  column-gap: 8rem;
  padding: 8rem 2rem;
}

.profileLinks{
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
}

.profileLinks .icon{
  display: none;
}

.profileHeader .headerText{
  font-size: 50px;
  line-height: 55px;
}

.subHeaderText{
  font-size: 30px;
  color: rgb(110, 110, 110);
}

.profileImage{
  height: 15rem;
}

.profileImage img{
  height: 100%;
}

/* Tablets and smaller */
@media (max-width: 769px){
  .header{
    padding-top: 1rem;
  }
  .profileHeader{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items:center;
    column-gap: 8rem;
    padding: 8rem 2rem;
  }

  .profileHeader .headerText{
    font-size: 40px;
    line-height: 45px;
  }

  .profileImage{
    height: 10rem;
  }

  .profileLinks .email{
    display: none;
  }
  .profileLinks .icon{
    display: block;
  }
}

